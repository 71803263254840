<template>
  <div id="mbti-result" class="section">
    <div class="jumbotron text-right">
      <div class="container">
        <h2>Tipe kepribadianmu adalah</h2>
        <h1>ESTP</h1>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <p>
            Anda yang memiliki kepribadian ESTP biasa disebut sebagai The Doers atau seorang Pelaku. Diperkirakan 4-5% kepribadian ESTP di dunia. Menurut dimensinya ESTP yakni Extroversion yaitu orang yang senang akan jaringan dan hubungan yang luas. Sensing yakni mereka yang berpandangan realistis dan menilai hal dengan konkret. Thinking yaitu mereka yang berpikir objektif tanpa melihat siapapun itu, dan Perception dimana mereka membuat keputusan berdasarkan kesepakatan.
          </p>
          <p>
            Ciri kepribadian ESTP adalah:
            <ul>
              <li> Tidak menyukai hal abstrak, serba cepat dan juga energik.</li>
              <li> Jarang bekerja dengan perencanaan dan senang melakukan hal yang berketerampilan.</li>
              <li> Menyenangkan dan sangat jeli.</li>
              <li> Fleksibel dan tentunya banyak akal..</li>
            </ul>
          </p>
          <p>
            Saran dan pekerjaan yang cocok:
            <ul>
              <li>
                Belajarlah memahami perasaan orang lain dan cobalah untuk sabar.
              </li>
              <li>
                Sesekali luangkan waktu untuk memikirkan masa depan, jangan selalu berpikir anda hidup untuk hari ini.
              </li>
              <li>
                Pekerjaan yang cocok adalah wirausaha, tenaga medis, atlet, polisi dan teknik.
              </li>
            </ul>
          </p>
          <p>
            Asmara yang cocok dengan ESTP adalah seseorang yang memiliki kepribadian ISTJ atau ISFJ yang mana tidak akan saling mendominasi dan sama-sama bermodalkan hal yang bersifat sensing atau konkret.
          </p>
        </div>
        <div class="col-lg-5">
          <img src="../../../assets/images/mbti/ESTP.png" alt="" class="illustration">
          <div class="media">
            <img src="../../../assets/images/mbti/Extraversion.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="extraversion">Extraversion</h5>
              <p>cenderung memiliki jaringan sosial yang luas</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Sensing.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="sensing">Sensing</h5>
              <p>lebih fokus terhadap gambaran masa depan</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Thinking.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="thinking">Thinking</h5>
              <p>lebih fokus terhadap implikasi sosial dibandingkan logika</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Perceiving.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="perceiving">Perceiving</h5>
              <p>menyukai hal yang bersifat terograrnisir dan terstruktur</p>
            </div>
          </div>
        </div>
      </div>
      <ShareButton :result="result" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ESTP",
  components: {
    ShareButton: () => import('@/components/ShareButton'),
  },
  data() {
    return {
      result: {
        name: 'ESTP',
        slug: 'estp'
      }
    }
  }
};
</script>

<style scoped>

</style>
